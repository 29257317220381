import { NextPage } from 'next'
import React from 'react'
import { DeprecatedPage } from '../components/DeprecatedPage/DeprecatedPage'
import { ROUTES } from '../constants/routes'

/**
 * @deprecated Page location moved
 */
const DeprecatedLoginPage: NextPage = () => {
  return <DeprecatedPage pathname={ROUTES.LOGIN} />
}

export default DeprecatedLoginPage

import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { CitrusError } from '../../pages/_error'

interface IProps {
  pathname: string
  query?: Record<string, string | string[] | undefined>
}

export const DeprecatedPage = ({ pathname, query: q }: IProps) => {
  console.warn(`Loading deprecated page: [${pathname}]`)
  const { query, ...router } = useRouter()
  const program_id: string | undefined =
    typeof query.program_id === 'string'
      ? query.program_id
      : query.program_id
      ? query.program_id[0]
      : undefined
  useEffect(() => {
    if (program_id && pathname) {
      router.push({
        pathname,
        query: {
          ...q,
          ...query,
          program_id,
        },
      })
    }
  }, [router, q, query, pathname, program_id])
  return program_id ? null : <CitrusError errorCode={404} />
}
